import { post } from '@/utils/request'


export function add(data) {
    return post('/room/Work_Item/addWorkItem', data)
}


export function getList(data) {
    return post('/room/Work_Item/workItemList', data)
}

export function getInfo(data) {
    return post('/room/work_Item/workItemInfo', data)
}

export function edit(data) {
    return post('/room/work_Item/editWorkItem', data)
}

export function del(data) {
    return post('/room/work_Item/delWorkItem', data)
}


export function getAllItems(data) {
    return post('/room/work_Item/selWorkItem', data)
}

